import React from "react";

const CalenderIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="19"
      viewBox="0 0 11 10"
      fill="none"
    >
      <path
        d="M10.56 0.9H8.25V0.1C8.25 0.045 8.2005 0 8.14 0H7.37C7.3095 0 7.26 0.045 7.26 0.1V0.9H3.74V0.1C3.74 0.045 3.6905 0 3.63 0H2.86C2.7995 0 2.75 0.045 2.75 0.1V0.9H0.44C0.196625 0.9 0 1.07875 0 1.3V9.6C0 9.82125 0.196625 10 0.44 10H10.56C10.8034 10 11 9.82125 11 9.6V1.3C11 1.07875 10.8034 0.9 10.56 0.9ZM10.01 9.1H0.99V4.35H10.01V9.1ZM0.99 3.5V1.8H2.75V2.4C2.75 2.455 2.7995 2.5 2.86 2.5H3.63C3.6905 2.5 3.74 2.455 3.74 2.4V1.8H7.26V2.4C7.26 2.455 7.3095 2.5 7.37 2.5H8.14C8.2005 2.5 8.25 2.455 8.25 2.4V1.8H10.01V3.5H0.99Z"
        fill="black"
      />
    </svg>
  );
};

export default CalenderIcon;
