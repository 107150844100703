// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../images/about-us/bg_dots.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../images/about-us/bg-white_left-to-right.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".bg_responsive {\n    min-height: 600px;\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n\n.bg-left_to_right {\n    min-height: 600px;\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat;\n    background-size: cover;\n}\n\n\n@media only screen and (max-width: 600px) {\n    .bg-left_to_right {\n       background: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n      background-size: contain;\n    }\n  }\n\n  .custom_width {\n    width: 100%;\n  }\n\n  @media only screen and (min-width: 992px) {\n    .custom_width {\n      width: 60% !important;\n    }\n  }", ""]);
// Exports
module.exports = exports;
