import React from "react";
const UserIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M5.86759 0.677307C5.2873 0.677307 4.72005 0.849382 4.23756 1.17177C3.75507 1.49416 3.37901 1.95239 3.15695 2.4885C2.93488 3.02462 2.87678 3.61454 2.98999 4.18368C3.10319 4.75282 3.38263 5.2756 3.79295 5.68593C4.20328 6.09625 4.72606 6.37568 5.2952 6.48889C5.86434 6.6021 6.45426 6.544 6.99038 6.32193C7.52649 6.09987 7.98472 5.72381 8.30711 5.24132C8.6295 4.75883 8.80157 4.19157 8.80157 3.61129C8.80157 2.83315 8.49246 2.08688 7.94223 1.53665C7.392 0.986422 6.64573 0.677307 5.86759 0.677307ZM5.86759 5.37168C5.51942 5.37168 5.17907 5.26843 4.88957 5.075C4.60008 4.88156 4.37444 4.60663 4.2412 4.28496C4.10796 3.96329 4.0731 3.60933 4.14103 3.26785C4.20895 2.92637 4.37661 2.6127 4.62281 2.36651C4.869 2.12031 5.18267 1.95265 5.52416 1.88472C5.86564 1.8168 6.21959 1.85166 6.54126 1.9849C6.86293 2.11814 7.13787 2.34377 7.3313 2.63327C7.52473 2.92276 7.62798 3.26312 7.62798 3.61129C7.62798 4.07817 7.44251 4.52593 7.11237 4.85607C6.78224 5.18621 6.33447 5.37168 5.86759 5.37168ZM11.1488 11.8264V11.2396C11.1488 10.1502 10.716 9.10546 9.94567 8.33514C9.17536 7.56483 8.13058 7.13206 7.04118 7.13206H4.694C3.6046 7.13206 2.55983 7.56483 1.78951 8.33514C1.01919 9.10546 0.586426 10.1502 0.586426 11.2396V11.8264H1.76002V11.2396C1.76002 10.4615 2.06913 9.71523 2.61936 9.165C3.16959 8.61477 3.91586 8.30566 4.694 8.30566H7.04118C7.81932 8.30566 8.56559 8.61477 9.11582 9.165C9.66605 9.71523 9.97516 10.4615 9.97516 11.2396V11.8264H11.1488Z"
        fill="black"
      />
    </svg>
  );
};

export default UserIcon;
