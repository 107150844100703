import React from "react";

const ClockIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 13 13"
      fill="none"
    >
      <path
        d="M6.16136 0.677307C2.75886 0.677307 0 3.43617 0 6.83867C0 10.2412 2.75886 13 6.16136 13C9.56386 13 12.3227 10.2412 12.3227 6.83867C12.3227 3.43617 9.56386 0.677307 6.16136 0.677307ZM6.16136 11.9548C3.33649 11.9548 1.04523 9.66354 1.04523 6.83867C1.04523 4.01379 3.33649 1.72254 6.16136 1.72254C8.98623 1.72254 11.2775 4.01379 11.2775 6.83867C11.2775 9.66354 8.98623 11.9548 6.16136 11.9548Z"
        fill="black"
      />
      <path
        d="M8.56443 8.57982L6.60325 7.16188V3.758C6.60325 3.69749 6.55374 3.64798 6.49322 3.64798H5.8317C5.77119 3.64798 5.72168 3.69749 5.72168 3.758V7.54559C5.72168 7.58135 5.73818 7.61436 5.76706 7.63498L8.04182 9.2936C8.09133 9.32936 8.16009 9.31836 8.19585 9.27022L8.58919 8.73385C8.62495 8.68297 8.61394 8.6142 8.56443 8.57982Z"
        fill="black"
      />
    </svg>
  );
};

export default ClockIcon;
