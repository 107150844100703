import React from "react";
const Star = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="15"
      viewBox="0 0 13 12"
      fill="none"
    >
      <path
        d="M4.9561 2.75164C5.43641 1.27339 5.67657 0.534261 6.04417 0.346001C6.33038 0.199423 6.66962 0.199423 6.95583 0.346001C7.32343 0.534261 7.56359 1.27339 8.0439 2.75164C8.17807 3.16457 8.24516 3.37104 8.37384 3.52202C8.47665 3.64265 8.60668 3.73712 8.75318 3.79763C8.93654 3.87336 9.15363 3.87336 9.58781 3.87336C11.1421 3.87336 11.9193 3.87336 12.2119 4.16479C12.4398 4.3917 12.5446 4.71433 12.4937 5.03183C12.4282 5.43962 11.7995 5.89642 10.542 6.81003C10.1907 7.06524 10.0151 7.19284 9.91128 7.36188C9.82832 7.49694 9.77865 7.64979 9.76638 7.80783C9.75102 8.00561 9.8181 8.21207 9.95227 8.625C10.4326 10.1033 10.6727 10.8424 10.486 11.2108C10.3406 11.4976 10.0662 11.697 9.74846 11.7466C9.3404 11.8104 8.71166 11.3536 7.45418 10.44C7.10293 10.1848 6.9273 10.0572 6.73445 10.0107C6.58036 9.97349 6.41964 9.97349 6.26555 10.0107C6.0727 10.0572 5.89707 10.1848 5.54582 10.44C4.28834 11.3536 3.6596 11.8104 3.25154 11.7466C2.93384 11.697 2.65939 11.4976 2.514 11.2108C2.32726 10.8424 2.56741 10.1033 3.04773 8.625C3.1819 8.21207 3.24898 8.00561 3.23362 7.80783C3.22135 7.64979 3.17168 7.49694 3.08872 7.36188C2.9849 7.19284 2.80927 7.06524 2.45801 6.81003C1.20053 5.89642 0.57179 5.43962 0.50634 5.03183C0.45538 4.71433 0.56021 4.3917 0.788058 4.16479C1.0807 3.87336 1.85786 3.87336 3.41219 3.87336C3.84637 3.87336 4.06346 3.87336 4.24682 3.79763C4.39332 3.73712 4.52335 3.64265 4.62616 3.52202C4.75484 3.37104 4.82193 3.16457 4.9561 2.75164Z"
        fill="url(#paint0_linear_596_476)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_596_476"
          x1="2.04762"
          y1="1.23809"
          x2="15"
          y2="15"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C63A26" />
          <stop offset="1" stop-color="#D0631A" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Star;
